import { useState, useCallback, useEffect, useRef } from "react";
import "./App.css";

import { Document, Page, pdfjs } from "react-pdf";
// var firebase = require("firebase");
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/app-check";

import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
interface PdfProxy {
  numPages: number;
}

const docs = {
  iso27001: "PN-EN-ISO_IEC-27001_2017-06E.pdf",
  iso27002: "PN-EN-ISO_IEC-27002_2017-06E.pdf",
  iso27005: "PN-ISO_IEC-27005_2014-01P_KOLOR.pdf",
  iso31000: "PN-ISO-31000_2018-08E_KOLOR.pdf",
};

type docKey = keyof typeof docs;

const firebaseConfig = {
  apiKey: "AIzaSyCH6Upc9OB8XVTLA2JiPHU2AlQ-v82vp1g",
  authDomain: "reading-room-af322.firebaseapp.com",
  projectId: "reading-room-af322",
  storageBucket: "reading-room-af322.appspot.com",
  messagingSenderId: "747763424182",
  appId: "1:747763424182:web:a1ce8e7ed598dde38cbf17",
  measurementId: "G-FGL5M8W4J2",
};

firebase.initializeApp(firebaseConfig);
const appCheck = firebase.appCheck();
appCheck.activate("6Le3AxAbAAAAAIBlIwi-ehOLHV56aFaU4EIevB2v");

const ui = new firebaseui.auth.AuthUI(firebase.auth());
function App() {
  const [doc, setDoc] = useState("");
  const [key, setKey] = useState<docKey | "">("");
  const [page, setPage] = useState(1);
  const [allPages, setAllPages] = useState(1);
  const onDocLoaded = useCallback((pdf: PdfProxy) => {
    setAllPages(pdf.numPages);
    setPage(1);
  }, []);

  const storageRef = useRef<null | firebase.storage.Reference>(null);
  const [authenticated, setAuthenticated] = useState(false);
  useEffect(() => {
    if (!authenticated) {
      storageRef.current = firebase.storage().ref();

      const config = {
        signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
        callbacks: {
          signInSuccessWithAuthResult: function () {
            setAuthenticated(true);
            return true;
          },
        },
      };
      ui.start("#firebaseui-auth-container", config);
    }
  }, [authenticated]);

  return (
    <div
      className="App"
      onKeyDown={({ key }) => {
        if (key === "ArrowLeft" && page > 1)
          setPage((prevPage) => prevPage - 1);
        else if (key === "ArrowRight" && page < allPages)
          setPage((prevPage) => prevPage + 1);
      }}
    >
      {!authenticated && <div id="firebaseui-auth-container"></div>}
      {authenticated &&
        firebase
          .auth()
          .currentUser?.email?.match(/[A-Za-z.]+@withintent\.com$/) && (
          <>
            <nav>
              {(Object.keys(docs) as [docKey]).map((k) => (
                <button
                  onClick={async () => {
                    const url = await storageRef.current
                      ?.child(docs[k])
                      .getDownloadURL();
                    setDoc(url);
                    setKey(k);
                    setPage(1);
                  }}
                  disabled={k === key}
                  key={k}
                >
                  {k}
                </button>
              ))}
            </nav>
            <main>
              <Document
                file={doc}
                onLoadSuccess={onDocLoaded}
                noData="Select a document to view."
                onLoadError={(error) =>
                  alert("Error while loading document! " + error.message)
                }
                loading="The document is leading…"
              >
                <Page pageNumber={page} />
              </Document>
            </main>
            <aside>
              <button
                onClick={() => setPage((prevPage) => prevPage - 1)}
                disabled={page === 1}
              >
                Previous
              </button>
              Page{" "}
              <input
                type="number"
                min={1}
                max={allPages}
                value={page}
                autoFocus
                onChange={(e) => {
                  const v = parseInt(e.target.value, 10);
                  if (v >= 1 && v <= allPages) setPage(v);
                }}
              />
              of {allPages}
              <button
                onClick={() => setPage((prevPage) => prevPage + 1)}
                disabled={page === allPages}
              >
                Next
              </button>
            </aside>
            <button
              onClick={() => {
                firebase.auth().signOut();
                setAuthenticated(false);
              }}
            >
              Log out
            </button>
          </>
        )}
      {authenticated &&
        !firebase.auth().currentUser?.email?.match(/@withintent\.com$/) && (
          <>
            <h1>You don’t have permissions to read anything here… </h1>
            <button
              onClick={() => {
                firebase.auth().signOut();
                setAuthenticated(false);
              }}
            >
              Log out
            </button>
          </>
        )}
    </div>
  );
}

export default App;
